<template>
  <b-modal
    v-model="modal"
    id="modallg"
    title="Upload Numbers"
    size="md"
    @ok="uploadItem"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-form-group class="mt-2" v-if="!selectedGroup">
      <label>Groups:</label>
      <b-form-checkbox-group
        id="checkbox-group-1"
        class="d-flex flex-column"
        v-model="item.groups"
        :options="groups"
        text-field="name"
        value-field="name"
        name="flavour-1"
      ></b-form-checkbox-group>
    </b-form-group>
    <b-form-group label="Upload file" label-for="input8">
      <b-form-file
        v-model="item.file"
        id="inpu8"
        accept=".csv"
        placeholder="Choose a file..."
      ></b-form-file>
      <a target="_blank" href="/template.csv" style="font-size: 11px" class="d-block mt-2"
        >Download File Format</a
      >
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
    },
    selectedGroup: {
      type: Object,
    },
  },
  data() {
    return {
      file: null,
      modal: false,
      item: {
        file: null,
        groups: null,
      },
    };
  },
  mounted() {
    this.modal = this.show;
  },
  computed: {
    ...mapGetters(["groups"]),
  },
  methods: {
    uploadItem() {
      this.$parent.uploadGroups(this.item);
    },
  },
};
</script>

<template>
  <b-modal
    v-model="modal"
    id="modallg"
    :title="title"
    size="md"
    @ok="submit"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-form-group label="Name Group" label-for="input4">
      <b-form-input type="text" id="input4" v-model="name"></b-form-input>
    </b-form-group>
  </b-modal>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      name: "",
      modal: false,
      title: "Create Group",
    };
  },
  mounted() {
    this.modal = this.show;
  },
  methods: {
    submit() {
      this.$emit("create", this.name);
    },
  },
};
</script>

<template>
  <div class="page row">
    <div class="col-12 grid-margin">
      <div
        class="actions mb-3 d-flex align-items-center justify-content-between"
      >
        <div>
          <button
            id="create"
            class="add btn btn-primary font-weight-bold todo-list-add-btn contact-buttons mr-2"
            @click="showCreateGroup = true"
            v-if="typeGroups && showGroups"
          >
            Create Group
          </button>
          <button
            id="create"
            class="add btn btn-primary font-weight-bold todo-list-add-btn contact-buttons mr-2"
            @click="showCreate = true"
            v-if="!showGroups"
          >
            Create Number
          </button>
          <button
            id="create"
            class="add btn btn-primary font-weight-bold todo-list-add-btn contact-buttons"
            @click="showUpload = true"
            v-if="!showGroups"
          >
            Upload Numbers
          </button>
          <button
            v-if="!showGroups && items && items.length > 0"
            class="add btn btn-primary font-weight-bold todo-list-add-btn contact-buttons"
            @click="downloadContacts"
          >
            Export Numbers
          </button>
          <button
            v-if="!showGroups && items && items.length > 0"
            class="add btn btn-primary font-weight-bold todo-list-add-btn contact-buttons"
            @click="deleteAll"
          >
            Delete All
          </button>
          <button
            v-if="!showGroups && items && items.length > 0"
            class="add btn btn-primary font-weight-bold todo-list-add-btn contact-buttons"
            @click="deleteSelected"
          >
            Delete Selected
          </button>
        </div>
        <b-form-group
          class="select col-3 align-center ml-audo mb-0 pl-0"
          label-for="input11"
          v-if="typeGroups && showGroups"
        >
          <b-form-input placeholder="Search Group" v-model="search" size="sm" />
        </b-form-group>
        <b-form-group
          class="select col-3 align-center ml-audo mb-0 pl-0"
          label-for="input11"
          v-if="!typeGroups && !showGroups"
        >
          <b-form-input
            placeholder="Search Contact"
            v-model="search"
            size="sm"
          />
        </b-form-group>
      </div>
      <div class="progress uploadProgressBar" v-if="showProgressBar">
        <div class="progress-bar bg-success bg-blue-pb" role="progressbar" :aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" v-bind:style="{width: progress + '%'}">{{ progress }}</div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-wrap justify-content-between">
            <h4 class="card-title">
              <div
                class="mr-1 mb-2"
                :class="{ pointer: selectedGroup }"
                @click="
                  selectedGroup ? (selectedGroup = null) : null,
                    selectedGroup ? (items = null) : null,
                    initData()
                "
                v-if="typeGroups"
              >
                Group list
              </div>
              <div v-if="typeGroups && selectedGroup">/</div>
              <div class="ml-1" v-if="selectedGroup">
                {{ `${selectedGroup.name} Numbers` }}
              </div>
            </h4>
            <b-nav-item-dropdown
              right
              noCaret
              class="preview-list d-md-flex"
              v-if="selectedGroup"
            >
              <template
                slot="button-content"
                class="p-0 m-0 d-flex align-items-center"
              >
                <button
                  class="btn p-0"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                </button>
              </template>
              <b-dropdown-item @click="deleteConfirm('group')"
                >Delete group</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </div>
          <div class="groups d-flex flex-wrap" v-if="typeGroups && showGroups">
            <table class="table center-aligned-table">
              <thead>
                <tr>
                  <!-- <th
                    class="pl-0 text-center"
                    style="width: 110px; display: block"
                  >
                    <div class="form-check mb-0">Selected</div>
                  </th> -->

                  <th
                    style="width: 20%"
                    class="sortable"
                    @click="
                      (nameSort = !nameSort), sortable('name')
                    "
                  >Name</th>
                  <th style="width: 20%">Owner</th>
                  <th
                    style="width: 20%"
                    class="sortable"
                    @click="
                      (createdSort = !createdSort), sortable('created')
                    "
                  >Created On</th>
                  <th
                    style="width: 20%"
                    class="text-center sortable"
                    @click="
                      (countSort = !countSort), sortable('contacts_num')
                    "
                  >Count</th>
                  <th style="width: 20%">Actions</th>
                </tr>
              </thead>
              <tbody v-if="items">
                <tr v-for="item in searchItem" :key="item.contact_id">
                  <!-- <td style="cursor: pointer">
                    <div class="form-check mb-0" style="margin-top: -15px">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="item.selected" />
                        <i class="input-helper"></i
                      ></label>
                    </div>
                  </td> -->
                  <td>{{ item.name }}</td>
                  <td>{{ item.owner }}</td>
                  <td>{{ getDate(item.created) }}</td>
                  <td class="text-center">
                    {{
                      item.contacts_num ||
                      (item.ani_list && item.ani_list.length)
                    }}
                  </td>
                  <td class="d-flex flex-column flex-md-row">
                    <button class="btn btn-primary" @click="showContacts(item)">
                      View Numbers
                    </button>
                    <button
                      class="btn btn-danger ml-0 mt-2 ml-md-2 mt-md-0"
                      @click="deleteConfirm('group', item)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
             class="mt-2"
              v-if="typeGroups && searchItem.length == 0 && groups.length > 0"
            >
              Zero matches, try another name
            </div>
          </div>

          <div class="table-responsive" v-else>
            <div
              class="col-12 pl-0"
              v-if="!searchItem && searchItem.length == 0"
            >
              No Data Found
            </div>
            <div
              class="col-12 pl-0"
              v-if="!searchItem || searchItem.length == 0"
            >
              No Data Found
            </div>
            <table class="table center-aligned-table" v-else>
              <thead>
                <tr>
                  <th
                    class="pl-0 text-center"
                    style="width: 110px; display: block"
                  >
                    <div class="form-check mb-0">Selected</div>
                  </th>
                  <th>{{ ani ? "Contact" : "Name" }}</th>
                  <th>{{ ani ? "Owner" : "Email" }}</th>
                  <th>{{ ani ? "Created on" : "Phone" }}</th>
                  <th v-if="!ani">Owner</th>
                  <th v-if="!typeGroups">Groups</th>
                  <!-- <th>Status</th> -->
                </tr>
              </thead>
              <tbody v-if="items">
                <tr v-for="item in searchItem" :key="item.contact_id">
                  <td style="cursor: pointer">
                    <div class="form-check mb-0" style="margin-top: -15px">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="item.selected" />
                        <i class="input-helper"></i
                      ></label>
                    </div>
                  </td>

                  <td v-if="!ani">
                    {{
                      `${item.first_name ? item.first_name : ""} ${
                        item.last_name ? item.last_name : ""
                      }`
                    }}
                  </td>
                  <td v-if="!ani">{{ item.email ? item.email : "" }}</td>
                  <td>{{ item.phone ? item.phone : "" }}</td>
                  <td v-if="!ani">{{ item.owner ? item.owner : "" }}</td>
                  <td v-if="ani">{{ selectedGroup.owner }}</td>
                  <td v-if="ani">{{ getDate(selectedGroup.created) }}</td>
                  <td v-if="!typeGroups">
                    <template v-if="item.groups">
                      <!-- {{item.groups}} -->
                      <span v-for="el in item.groups" :key="el.name"
                        >{{ el.name }},
                      </span>
                    </template>
                    <template v-else> Not specified </template>
                  </td>
                  <td style="width: 60px">
                    <a
                      href="#"
                      class="icon mr-1 text-muted p-2"
                      v-if="!ani"
                      @click="(showUpdate = true), (currentItem = item)"
                      ><i class="mdi mdi-grease-pencil"></i
                    ></a>
                    <button
                      href="#"
                      class="icon mr-1 text-muted p-2"
                      @click="deleteConfirm('contact', item)"
                    >
                      <i class="mdi mdi-delete"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Create
      :show="showCreate"
      v-if="showCreate"
      @close="showCreate = false"
      @created="initData(), (showCreate = false)"
      :type="typeGroups"
      :ani="ani ? selectedGroup : null"
      :selectedGroup="selectedGroup"
    />
    <CreateGroup
      :show="showCreateGroup"
      v-if="showCreateGroup"
      @close="showCreateGroup = false"
      @create="createGroup($event), (showCreateGroup = false)"
    />
    <Update
      :item="currentItem"
      :show="showUpdate"
      v-if="showUpdate"
      @close="showUpdate = false"
      @update="initData(), (showUpdate = false)"
    />
    <UploadWrapper
      @upload="initData(), (showUpload = false)"
      @close="showUpload = false"
      @updateProgress="updateProgressBar($event)"
      @showProgress="updateShowProgressBar($event)"
      :ani="ani ? true : false"
      :selectedGroup="selectedGroup"
    >
      <Upload
        :selectedGroup="selectedGroup"
        :show="showUpload"
        @close="showUpload = false"
        v-if="showUpload"
      />
    </UploadWrapper>
  </div>
</template>

<script>
import Create from "../components/contacts/Create";
import CreateGroup from "../components/contacts/CreateGroup";

import Update from "../components/contacts/Update";
import Upload from "../components/contacts/Upload";
import UploadWrapper from "@/components/UploadWrapper";
import { mapGetters } from "vuex";

export default {
  props: ["type", "ani"],
  data() {
    return {
      items: [],
      showCreate: false,
      showCreateGroup: false,
      showUpdate: false,
      showUpload: false,
      currentItem: null,
      selectedGroup: null,
      search: "",
      showProgressBar: false,
      progress: 0,
      key: null,
      nameSort: false,
      createdSort: false,
      countSort: false
    };
  },
  components: {
    Create,
    Update,
    Upload,
    UploadWrapper,
    CreateGroup,
  },
  computed: {
    ...mapGetters(["admin"]),
    groups() {
      return this.$store.state.groups;
    },
    typeGroups() {
      this.$store.commit("updateLoading", true);
      return this.type == "groups";
    },
    searchItem() {
      let items =
        this.typeGroups && !this.selectedGroup ? this.groups : this.items;
      if (this.search !== "" && this.search.length > 2) {
        items = [];
        let arr = this.typeGroups ? this.groups : this.items;
        let key = this.typeGroups ? "name" : "phone";
        arr.forEach((el) => {
          if (el[key].toString().toLowerCase().includes(this.search.toLowerCase())) {
            items.push(el);
          }
        });
      }
      return items;
    },
    showGroups() {
      return this.typeGroups && !this.selectedGroup;
    },
  },
  mounted() {
    const name = this.$route.params.name;
    if (name) {
      this.selectedGroup = {
        name,
      };
      this.loadGroup();
    } else this.initData();
  },
  methods: {
    async initData() {
      if (this.selectedGroup) {
        this.loadGroup();
      } else {
        await this.$store.dispatch("loadGroups", this.ani);
      }
      // this.$store.dispatch("loadFiles");
    },
    getDate(date) {
      return this.$moment.unix(date).format("MMMM Do YYYY, hh:mm");
    },
    sortable(key) {
      let itemsToSort = this.groups
      this.key =key
      itemsToSort.sort(this.compare);
      this.isSortAsc = !this.isSortAsc
      this.$store.commit("updateItems", itemsToSort);
    },
    compare(a, b) {
      let type;
      if (this.key == 'name') { type = this.nameSort }
      if (this.key == 'created') { type = this.createdSort }
      if (this.key == 'contacts_num') { type = this.countSort }

      let item1 = a[this.key];
      let item2 = b[this.key];

      if (this.key == 'name') {
        item1 = item1.toLowerCase()
        item2 = item2.toLowerCase()
      }

      let comparison = 0;

      if (type) {
        if (item1 > item2) {
          comparison = 1;
        } else if (item1 < item2) {
          comparison = -1;
        }
      }else {
        if (item1 < item2) {
          comparison = 1;
        } else if (item1 > item2) {
          comparison = -1;
        }
      }

      return comparison;
    },
    deleteAll() {

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete all!",
        })
        .then((result) => {
          if (result.value) {
            if (this.ani && this.selectedGroup) {
              this.selectedGroup.ani_list = [];
              this.$axios
                .post(
                  `/ani_groups/update/${this.selectedGroup.id}`,
                  this.selectedGroup
                )
                .then((res) => {
                  this.$swal.fire("Contacts deleted!", "", "success");
                  this.initData();
                })
                .catch((err) => {
                  this.$swal.fire(
                    "Deleted all contacts!",
                    err.response.data.message,
                    "success"
                  );
                });
            } else {
              this.$axios
                .post("/contacts/delete_all/")
                .then((res) => {
                  this.$swal.fire("Deleted all contacts!", "", "success");
                  this.loadItems();
                })
                .catch((err) => {
                  this.$swal.fire(
                    "Deleted all contacts!",
                    err.response.data.message,
                    "success"
                  );
                });
            }
          }
        });
    },
    deleteSelected() {

      let isAnyItemSelected = this.items.some((el) => {
        return el.selected == true
      })

      if (!isAnyItemSelected) {
        this.$swal
          .fire({
            title: "Error!",
            text: "Please select at least one Item",
            showCancelButton: false,
            confirmButtonText: "Ok",
            confirmButtonColor: "#3085d6"
          })

        return
      }

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete selected!",
        })
        .then((result) => {
          if (result.value) {
            const deleted = [];
            if (!this.ani && this.selectedGroup) {
              this.items.forEach(async (el, index) => {
                if (el.selected) {
                  await this.$axios
                    .post(`/contacts/delete/${el.contact_id}`)
                    .then(async (res) => {
                      deleted.push(el.phone);
                    })
                    .catch((err) => {
                      this.$swal.fire(
                        "Error!",
                        err.response.data.message,
                        "error"
                      );
                    });
                  if (index == this.items.length - 1) {
                    this.$swal.fire("Contacts deleted!", "", "success");
                    this.loadItems();
                  }
                }
              });
            } else {
              const phone_list = [];
              this.items.forEach((el) => {
                if (!el.selected) {
                  phone_list.push(el.phone);
                }
              });
              this.selectedGroup.ani_list = phone_list;

              this.items.forEach(async (el, index) => {
                if (el.selected) {
                  await this.$axios
                    .post(
                      `/ani_groups/update/${this.selectedGroup.id}`,
                      this.selectedGroup
                    )
                    .then((res) => {
                      this.$swal.fire("Contacts deleted!", "", "success");
                      this.initData();
                    })
                    .catch((err) => {
                      this.$swal.fire(
                        "Deleted all contacts!",
                        err.response.data.message,
                        "error"
                      );
                    });
                }
              })
            }
          }
        });
    },
    createGroup(name) {
      let url = "/contacts/group/create";
      if (this.ani) url = "/ani_groups/create";

      if (name == "") {
        this.$swal.fire(
          "Group not created",
          "Please enter the group name",
          "error"
        );
        return
      }
      this.$axios
        .post(url, {
          group_name: name,
        })
        .then((res) => {
          this.$swal.fire("Group successfully created", "", "success");
          this.$store.dispatch("loadGroups", this.ani);
        })
        .catch((err) => {
          this.$swal.fire(
            "Group not created",
            err.response.data.message,
            "error"
          );
        });
    },
    async loadItems() {
      this.$store.commit("updateLoading", true);
      await this.$axios
        .get(`${this.admin}/contacts/list`)
        .then((res) => {
          this.items = [];
          res.data.contacts.forEach((el) => {
            el.selected = false;
            this.items.push(el);
          });
          this.$store.commit("updateLoading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("updateLoading", false);
        });
    },
    loadGroup() {
      const url = this.ani 
        ? `/ani_groups/${this.selectedGroup.id}`
        : `/contacts/groups/${this.selectedGroup.name}`;
      this.$axios
        .get(url)
        .then((res) => {
          this.items = [];
          if (this.ani) {
            this.items = res.data.ani_list.map((el) => {
              return {
                selected: false,
                phone: el,
              };
            });
          } else {
            this.items = res.data.contacts.map((el) => {
              return {
                selected: false,
                ...el,
              };
            });
          }

          this.$store.commit("updateLoading", false);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("updateLoading", false);
        });
    },
    deleteGroup(name) {
      const group = this.ani ? name.id : name.name || this.selectedGroup.name;
      const url = this.ani ? "/ani_groups/delete/" : "/contacts/groups/delete/";
      this.$axios
        .post(`${url}${group}`)
        .then(() => {
          if (this.selectedGroup) {
            this.selectedGroup = null;
          }
          this.$swal.fire(
            `${this.ani ? name.name : group} deleted`,
            "",
            "success"
          );
          this.initData();
        })
        .catch((err) => {
          this.$swal.fire(`Error`, err.response.data.error, "error");
        });
    },
    showContacts(item) {
      this.$store.commit("updateLoading", true);
      this.items = null;
      this.selectedGroup = item;
      this.loadGroup();
    },
    deleteConfirm(type = "contact", item) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            if (type == "group") {
              if (!item) {
                this.deleteGroup(this.selectedGroup);
              }else {
                this.deleteGroup(item);
              }
            } else {
              this.onDelete(item);
            }
          }
        });
    },
    updateShowProgressBar(showHidePB) {
      this.showProgressBar = showHidePB
    },
    updateProgressBar(progressPercentage) {
      this.progress = progressPercentage
    },
    downloadContacts() {

      let csvData = 'Phone\n'

      this.items.forEach((el, key) => {
        if (el['phone'] !== undefined && el['phone'] !== null) {
          if (key == this.items.length - 1) { csvData += el['phone'].toString() }else {
            csvData += el['phone'].toString() + ','
          }
        }else { csvData += ' -' }
        csvData += "\n";
      })

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      anchor.target = '_blank';
      anchor.download = 'contacts.csv';
      anchor.click();

      // this.$axios
      //   .get("/contacts/download", { responseType: "blob" })
      //   .then((res) => {
      //     const url = window.URL.createObjectURL(new Blob([res.data]));
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "contacts.csv");
      //     document.body.appendChild(link);
      //     link.click();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    onDelete(item) {
      if (this.ani && this.selectedGroup) {
        const index = this.selectedGroup.ani_list.indexOf(item.phone)
        if(index > -1) this.selectedGroup.ani_list.splice(index, 1)
        this.$axios
          .post(
            `/ani_groups/update/${this.selectedGroup.id}`,
            this.selectedGroup
          )
          .then((res) => {
            this.$swal.fire(
            "Deleted!",
            "Your contanct has been deleted.",
            "success"
          );
            this.initData();
        })
        .catch((err) => {
          this.$swal.fire("Error", err.response.data.error, "error");
        });
      } else {
        this.$axios
        .post(`${this.admin}/contacts/delete/${item.contact_id}`)
        .then(() => {
          if (this.typeGroups || this.selectedGroup) {
            this.loadGroup();
          } else {
            this.loadItems();
          }
          this.$swal.fire(
            "Deleted!",
            "Your contanct has been deleted.",
            "success"
          );
        })
        .catch((err) => {
          this.$swal.fire("Error", err.response.data.error, "error");
        });
      }
    },
  },
};
</script>

<style lang="scss">
.sortable {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right center;
}
.form-check {
  margin-top: 0;
}
.pointer {
  text-decoration: underline;
}
.pointer,
.mdi-trash-can-outline {
  cursor: pointer;
  z-index: 100;
}
.icon {
  border: none;
  background: none;
}
.card-title {
  display: flex;
}
.uploadProgressBar {
  margin: 15px 0;
  background-color: #dcdcdc;
  height: 16px;
}
.bg-blue-pb {
  background-color: #007bff !important;
}
.actions {
  display: flex;
  @media (max-width: 525px) {
    align-items: flex-start !important;
    & > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .btn {
        margin-left: 0 !important;
        margin-bottom: 0.5rem;
      }
    }
  }
}
.page {
  position: relative;
  min-height: 400px;
}
.group {
  .card-body {
    padding: 1.3rem 0.875rem;
  }
}
.contact-buttons {
  margin-bottom: 10px;
}
</style>
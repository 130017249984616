<template>
  <Form
    :show="show"
    :item="item"
    title="Update contact"
    @submit="update"
    @close="$emit('close')"
    @hide="$emit('close')"
  />
</template>

<script>
import Form from "./Form";
export default {
  props: {
    item: {
      type: Object,
    },
    show: {
      type: Boolean,
    },
  },
  components: {
    Form,
  },
  methods: {
    update(user) {
      user["contact_id"] = this.item.contact_id;
      this.$axios
        .post(`/contacts/update`, user)
        .then(() => {
          this.$swal.fire(
            "Contact updated!",
            `Contact ${user.first_name} ${user.last_name} updated`,
            "success"
          );
          this.$emit('close');
          this.$parent.initData();
        })
        .catch((err) => {
          this.$swal.fire("Error", err.response.data.error, "error");
        });
    },
  },
};
</script>

